<template>
    <div>
        <styled-button
            :loading="loading"
            :disabled="loading"
            :loader-size="15"
            small
            outline
            @click="onPlaybookSelection(item)">
            {{ item.deployed ? 'RE-DEPLOY' : 'DEPLOY' }}
        </styled-button>
    </div>
</template>

<script>
import StyledButton from '@/components/globals/StyledButton';
import { mapState } from 'vuex';

export default {
    components: {
        StyledButton
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        ...mapState({
            currentDealerId: (state) => state.dealer.currentDealerId,
        })
    },
    methods: {
        onPlaybookSelection(playbook) {
            this.loading = true;
            // If the play has an existing onboarding go to it
            if (playbook.onboarding) {
                this.$router.push({
                    name: 'dealer-onboarding',
                    params: {
                        onboarding_id: playbook.onboarding
                    }
                });
            }
            // Otherwise let's start a new onboarding for the play
            else {
                this.$store.dispatch('startNewOnboarding', {
                    dealerId: this.currentDealerId,
                    productIds: [ playbook.plays?.data?.[0]?.product_id ],
                    playId: playbook.plays?.data?.[0]?.id
                });
            }
        }
    }
};
</script>